import { ethers } from "ethers";
import { VenlyProvider } from "@venly/web3-provider";
import userService from "../../services/api/userManagement";
import userSignUp from "../../services/api/signup";
import NFTCollectionABI from "../../services/contract/NFTCollection.json";
import NFTMarketpalceABI from "../../services/contract/NFTMarketplace.json";
import ERC721ABI from "../../services/contract/ERC721.json";
import NFTABI from "../../services/contract/NFT.json";
import jwt_decode from "jwt-decode";
import { markRaw } from "vue";

const options = {
  clientId: process.env.VUE_APP_VENLY_CLIENT_ID,
  skipAuthentication: true,
  // environment: process.env.VUE_APP_NETWORK === "Mainnet" ? "prod" : "sandbox",
  secretType: "MATIC",
};

const getRandomnumber = async () => {
  let randomChars = "0123456789";
  let result = "";
  for (let i = 0; i < 8; i++) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }

  return result;
};

const getRandomString = async () => {
  let randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let results = "";

  for (let i = 0; i < 6; i++) {
    results += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }

  return results;
};

const initialState = {
  isConnected: false,
  isConnecting: false,
  provider: null,
  signer: null,
  account: "",
  networkError: false,
  user: null,
  sessionToken: null,
  metamaskInitErrorMessage: "",
  isProviderEnabled: false,
  latestBlockNumber: null,
  venly: null,
  contracts: {
    Collections: null,
    Marketplace: null,
    BetaMarketplace: null,
  },
  collectionContracts: {
    NFT: null,
    NFTs: null,
  },
};
export default {
  namespaced: true,
  state: initialState,
  getters: {
    account: (state) => state.account,
    provider: (state) => state.provider,
    signer: (state) => state.signer,
    networkError: (state) => state.networkError,
    isConnected: (state) => state.isConnected,
    isConnecting: (state) => state.isConnecting,
    user: (state) => state.user,
    sessionToken: (state) => state.sessionToken,
    contracts: (state) => state.contracts,
    isProviderEnabled: (state) => state.isProviderEnabled,
    metamaskInitErrorMessage: (state) => state.metamaskInitErrorMessage,
    collectionContracts: (state) => state.collectionContracts,
    venly: (state) => state.venly,
  },
  mutations: {
    setProvider(state, provider) {
      state.provider = provider;
    },
    setSigner(state, signer) {
      state.signer = signer;
    },
    setAccount(state, account) {
      state.account = account;
    },
    setNetworkError(state, networkError) {
      state.networkError = networkError;
    },
    setIsConnected(state, isConnected) {
      state.isConnected = isConnected;
    },
    setIsConnecting(state, isConnecting) {
      state.isConnecting = isConnecting;
    },
    setUser(state, user) {
      state.user = user;
    },
    setSessionToken(state, sessionToken) {
      state.sessionToken = sessionToken;
    },
    setContracts(state, contracts) {
      state.contracts = contracts;
    },
    setProviderEnabled(state, isProviderEnabled) {
      state.isProviderEnabled = isProviderEnabled;
    },
    setMetamaskInitErrorMessage(state, metamaskInitErrorMessage) {
      state.metamaskInitErrorMessage = metamaskInitErrorMessage;
    },
    setCollectionContracts(state, collectionContracts) {
      state.collectionContracts = collectionContracts;
    },
    setLatestBlockNumber(state, latestBlockNumber) {
      state.latestBlockNumber = latestBlockNumber;
    },
    setVenly(state, venly) {
      state.venly = venly;
    },
  },
  actions: {
    async initProvider({ commit }, selectedProvider) {
      commit("setProviderEnabled", false);
      if (selectedProvider === "metamask") {
        commit("setMetamaskInitErrorMessage", "");
        if (typeof window.ethereum !== "undefined") {
          try {
            await window.ethereum.enable();
            commit("setProviderEnabled", true);
            return true;
          } catch (error) {
            commit("setMetamaskInitErrorMessage", "The user has refused access to MetaMask");
            return false;
          }
        } else {
          commit("setMetamaskInitErrorMessage", "MetaMask not installed");
          return false;
        }
      } else {
        commit("setProviderEnabled", true);
        return true;
      }
    },

    async initWeb3({ commit }, selectedProvider) {
      commit("setProvider", null);

      let provider, venly;

      switch (selectedProvider) {
        case "venly": {
          venly = new VenlyProvider();
          provider = await venly.createProvider(options);

          console.log(provider.connected);
          break;
        }
        // case "walletConnect": {
        //   provider = new WalletConnectProvider({ infuraId: "9f65f2e7dc324b6fba99c874cecfbadd" });

        //   break;
        // }
        case "metamask":
        default: {
          provider = window.ethereum;

          break;
        }
      }

      sessionStorage.setItem("selectedProvider", selectedProvider);
      const providerWeb3 = new ethers.providers.Web3Provider(provider);

      commit("setProvider", markRaw(providerWeb3));
      commit("setVenly", venly);
      return providerWeb3;
    },

    async getAccountInfo({ commit, rootState }, selectedProvider) {
      const getAccounts = async () => {
        try {
          const provider = rootState.blockchain.provider;
          const accounts = await provider.listAccounts();
          const signer = provider.getSigner(accounts[0]);

          commit("setAccount", accounts[0]);
          commit("setSigner", markRaw(signer));
        } catch (error) {
          console.error("Error: Could not get account (getAccounts) with Common Web3", error);
        }
      };

      if (selectedProvider == "venly") {
        try {
          const venly = rootState.blockchain.venly;

          // TODO: Check where comes the account and commit (setAccount)
          const profile = await venly.connect.api.getProfile();
          console.log(profile);
        } catch (error) {
          console.info("User not logged in with venly, trying to reconnect", error);
          await getAccounts();
        }
      } else {
        await getAccounts();
      }
    },

    async checkNetworkStatus({ commit, rootState }) {
      commit("setNetworkError", "");

      const provider = rootState.blockchain.provider;
      const { chainId } = await provider.getNetwork();

      const isValidChain = Number(chainId) === Number(process.env.VUE_APP_ChainID);

      if (!isValidChain) {
        commit("setNetworkError", `Please change Network to ${process.env.VUE_APP_NETWORK}`);
      }

      return isValidChain;
    },

    async checkTokenExpiration({ commit, state }) {
      try {
        const token = jwt_decode(sessionStorage.getItem("jwt"));
        const currentSnap = new Date() / 1000;

        if (state.token) {
          if (token?.exp > currentSnap) {
            commit("setIsConnected", true);
          } else {
            commit("setIsConnected", false);
          }
        } else {
          commit("setIsConnected", false);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async validateOrCreateUser({ commit, rootState, dispatch }) {
      const account = rootState.blockchain.account?.toLowerCase();
      if (account) {
        const userExist = await userService.checkuserexist(account);

        if (!userExist?.data?.msg) {
          const randomNumber = await getRandomnumber();
          await userService.setFriendCode(account, randomNumber);
          sessionStorage.setItem("friend_code", randomNumber);
          await userSignUp.userSignUp(account, randomNumber);
        }

        const randomString = await getRandomString();
        sessionStorage.setItem("vrcode", randomString);
        const resp = await dispatch(
          "auth/setVrCode",
          {
            vrCode: randomString,
            walletAddress: account,
          },
          { root: true }
        );

        commit("setUser", resp.auth?.user);
        commit("setSessionToken", resp.auth?.sessionToken);
        commit("setIsConnected", true);
      }
    },

    async createNFTContracts({ commit, rootState }, collectionAddress) {
      const signer = rootState.blockchain.signer;

      const NFTContract = new ethers.Contract(collectionAddress, ERC721ABI, signer);
      const NFTsContract = new ethers.Contract(collectionAddress, NFTABI, signer);

      commit("setCollectionContracts", {
        NFT: NFTContract,
        NFTs: NFTsContract,
      });
    },

    async initContracts({ commit, rootState }) {
      const signer = rootState.blockchain.signer;

      //TODO: Blockchain contract put on .env
      const CollectionsContract = new ethers.Contract(
        process.env.VUE_APP_NFT_CONTRACT,
        NFTCollectionABI,
        signer
      );

      const MarketplaceContract = new ethers.Contract(
        process.env.VUE_APP_MARKETPLACE_CONTRACT,
        NFTMarketpalceABI,
        signer
      );

      commit("setContracts", {
        Collections: CollectionsContract,
        Marketplace: MarketplaceContract,
      });
    },

    async connect({ dispatch, commit }, selectedProvider) {
      commit("setIsConnecting", true);
      const isProviderEnabled = await dispatch("initProvider", selectedProvider);

      if (isProviderEnabled) {
        await dispatch("initWeb3", selectedProvider);

        if (isProviderEnabled) {
          const isValidNetwork = await dispatch("checkNetworkStatus");

          if (isValidNetwork) {
            await dispatch("getAccountInfo", selectedProvider);
            await dispatch("validateOrCreateUser");
            await dispatch("initContracts");
          }
        }
      }
      commit("setIsConnecting", false);
    },

    async disconnect() {
      console.log("cleaning");
      sessionStorage.removeItem("selectedProvider");
      localStorage.removeItem("userinfo_uuid");
      localStorage.removeItem("walletconnect");
      localStorage.removeItem("wc_account");
      sessionStorage.removeItem("jwt");
      sessionStorage.removeItem("vrcode");
      sessionStorage.removeItem("friend_code");
    },
  },
};
