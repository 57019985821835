<template>
  <router-view :key="$route.path" />
</template>

<script>
export default {
  name: "App",
  mounted: async function () {
    const provider = sessionStorage.getItem("selectedProvider");
    if (provider) await this.$store.dispatch("blockchain/connect", provider);
  },
};
</script>
