<template>
  <section class="item-detail-section section-space">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 pe-xl-5">
          <div class="item-detail-content">
            <div class="item-detail-img-container mb-4">
              <img :src="nftData.image.cachedUrl" alt="" class="w-100 rounded-3">
            </div><!-- end item-detail-img-container -->
            <div class="item-detail-tab">
              <ul class="nav nav-tabs nav-tabs-s1" id="myTab" role="tablist">
      <li class="nav-item" role="presentation" v-for="list in nftData.owners || [nftData.mint]" :key="list.id">
        <button class="nav-link" :class="list.isActive" :id="list.slug" data-bs-toggle="tab" :data-bs-target="list.bsTarget" type="button">{{ list.title }}</button>
      </li>
    </ul>
              <div class="tab-content mt-3" id="myTabContent">
                <div class="tab-pane fade show active" id="owners" role="tabpanel" aria-labelledby="owners-tab">
                  <div class="item-detail-tab-wrap">
                    <div class="card-media card-media-s2 mb-3" v-for="item in nftData.itemDetailOwnerList" :key="item.id">
                      <router-link :to="item.path" class="card-media-img flex-shrink-0 d-block">
                        <img :src="item.avatar" alt="avatar">
                      </router-link>
                      <div class="card-media-body text-truncate">
                        <p class="fw-semibold text-truncate"><a :href="item.path" class="text-black">{{ nftData.mint }}</a></p>
                        <p class="small">{{ nftData.mint.mintaddress }}</p>
                      </div>
                    </div><!-- end card -->
                  </div><!-- end item-detail-tab-wrap -->
                </div><!-- end tab-pane -->
                <div class="tab-pane fade" id="bids" role="tabpanel" aria-labelledby="bids-tab">
                  <div class="item-detail-tab-wrap">
                    <div class="card-media card-media-s2 mb-3" v-for="item in nftData.itemDetailBidsList" :key="item.id">
                      <router-link :to="item.path" class="card-media-img flex-shrink-0 d-block">
                        <img :src="item.avatar" alt="avatar">
                      </router-link>
                      <div class="card-media-body text-truncate">
                        <p class="fw-semibold text-black text-truncate">{{ item.title }}</p>
                        <p class="small">{{ item.date }}</p>
                      </div>
                    </div><!-- end card -->
                  </div><!-- end item-detail-tab-wrap -->
                </div><!-- end tab-pane -->
                <div class="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
                  <div class="item-detail-tab-wrap">
                    <div class="card-media card-media-s2 mb-3" v-for="item in nftData.itemDetailHistoryList" :key="item.id">
                      <router-link :to="item.path" class="card-media-img flex-shrink-0 d-block">
                        <img :src="item.avatar" alt="avatar">
                      </router-link>
                      <div class="card-media-body text-truncate">
                        <p class="fw-semibold text-black text-truncate">{{ item.title }}</p>
                        <p class="small text-truncate">{{ item.subTitle }}</p>
                      </div>
                    </div><!-- end card -->
                  </div><!-- end item-detail-tab-wrap -->
                </div><!-- end tab-pane -->
              </div>
            </div>
          </div><!-- end item-detail-content -->
        </div><!-- end col -->
        <div class="col-lg-6">
          <div class="item-detail-content mt-4 mt-lg-0">
            <h1 class="item-detail-title mb-2">{{ nftData.name }}</h1>
            <div class="item-detail-meta d-flex flex-wrap align-items-center mb-3">
              <span class="item-detail-text-meta">{{ nftData.contract.name }}</span>
              <span class="dot-separeted"></span>
              <span class="item-detail-text-meta">{{ nftData.contract.symbol }}</span>
              <span class="dot-separeted"></span>
              <span class="item-detail-text-meta" v-html="nftData.contract.totalSupply"></span>
            </div>
            <p class="item-detail-text mb-4">{{ nftData.description }}</p>
            <div class="item-credits">
              <div class="row g-4">
                <div class="col-xl-6" v-for="owner in nftData.owners" :key="owner.id">
                  <div class="card-media card-media-s1">
                    <router-link :to="owner.path" class="card-media-img flex-shrink-0 d-block">
                      <img :src="owner.avatar" alt="avatar">
                    </router-link>
                    <div class="card-media-body">
                      <router-link :to="owner.path" class="fw-semibold">{{ owner.title }}</router-link>
                      <p class="fw-medium small">{{ owner.subTitle }}</p>
                    </div>
                  </div><!-- end card -->
                </div><!-- end col-->
                <!-- Add similar loop for other data if needed -->
              </div><!-- end row -->
            </div><!-- end row -->
            <!-- Add other content using nftData -->
          </div><!-- end item-detail-content -->
        </div><!-- end col -->
      </div><!-- end row -->
    </div><!-- .container -->
    <!-- Modal -->
    <!-- ... -->
  </section><!-- end item-detail-section -->
</template>

<script>
export default {
  name: 'ItemDetailSection',
  props: {
    nftData: {
      type: Object,
      required: true
    }
  }
}
</script>
