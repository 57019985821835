<template>
  <section class="author-section section-space">
    <div class="container">
      <div class="row">
        <div class="col-xl-3">
          <AuthorSidebar
            :user="user"
            :followingNum="SectionData.authorSidebarData.sidebarWidget.followingNum"
            :followingText="SectionData.authorSidebarData.sidebarWidget.followingText"
            :followerNum="SectionData.authorSidebarData.sidebarWidget.followerNum"
            :followerText="SectionData.authorSidebarData.sidebarWidget.followerText"
            :avatars="SectionData.authorSidebarData.sidebarWidget.followersAvatar"
          />
        </div>
        <!-- end col -->
        <div class="col-xl-9 ps-xl-4">
          <div class="author-items-wrap">
            <h3>{{ SectionData.profileData.title }}</h3>
            <div class="gap-2x"></div>
            <div class="row g-gs">
              <div v-if="ownedNfts.length === 0" class="col-md-12">
                No Data
              </div>
              <div v-else class="col-md-4" v-for="nft in ownedNfts" :key="nft.tokenId">
                <div class="card card-full body-container">
                  <div class="card-image" @click="gotoDetail(nft)">
                  <!-- <div class="card-image"> -->
                    <img
                      v-if="nft.image && 'originalUrl' in nft.image"
                      :src="nft.image.originalUrl"
                      class="card-img-top"
                      alt="art image"
                    />
                    <img
                      v-else
                      src="https://gate.scenez.io/ipfs/Qme71LKv3QcaeWJB6Fh7SH8Yo87JXDkqSsaoE3HWqk2EbB"
                      class="card-img-top"
                      alt="art image"
                    />
                  </div>
                  <div class="card-body p-4">
                    <h5 v-if="nft.name" class="card-title mb-0">
                      {{ nft.name }}
                    </h5>
                    <h5 v-else>Not supported</h5>
                    <!-- ... (other card content) ... -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end author-items-wrap -->
        </div>
        <!-- end col-lg-8 -->
      </div>
      <!-- end row -->
    </div>
    <!-- .container -->
    <!-- Modal -->
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-hidden="true">
      <!-- ... (modal content) ... -->
    </div>
    <!-- end modal-->
  </section>
  <!-- end author-section -->
</template>

<script>
import SectionData from "@/store/store.js";
import AuthorSidebar from "@/components/common/AuthorSidebar";
import nftManagement from "@/services/api/nftManagement";
// import Swal from "sweetalert2";

export default {
  name: "ProfileSection",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  components: {
    AuthorSidebar,
  },
  data: () => ({
    SectionData,
    currentaddress: "",
    ownedNfts: [],
  }),
  mounted() {
    this.getOwnedNFTs();
  },
  methods: {
    async gotoDetail(nft) {
      if (nft.raw && nft.raw.metadata && 'name' in nft.raw.metadata) {
        this.$router.push({
          name: 'itemDetails',
          params: {
            address: nft.contract.address,
            tokenid: nft.tokenId,
            formatedTitle: nft.raw.metadata.name.replaceAll(/[ #]/g, '-'),
            title: nft.raw.metadata.name,
          },
        });
      }
    },

    async getOwnedNFTs() {
      this.currentaddress = this.$store.state.blockchain.account;
      try {
        const { data, status } = await nftManagement.getNFTsByOwner(this.user.account);
        if (status === 200) {
          this.ownedNfts = data.ownedNfts;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>


<style lang="css" scoped>
.details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.btn,
.author-link,
.card-price-wrap {
  z-index: 2;
  position: relative;
}

.card-img-top {
  height: 230px;
}

#special {
  color: #25be40;
}

#issue_msg {
  color: red;
  text-align: center;
}

.body-container {
  cursor: pointer !important;
}
</style>
