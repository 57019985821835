import { createWebHistory, createRouter } from "vue-router";
import jwt_decode from "jwt-decode";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../pages/Home.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/download",
    name: "Download",
    component: () => import("../pages/download.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/success",
    name: "Success",
    component: () => import("../pages/Success.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/collections/:id",
    name: "ProductDetail",
    component: () => import("../pages/ProductDetail.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/assets/create",
    name: "CreateItem",
    component: () => import("../pages/CreateItem.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/assets/edit/:title/:collection",
    name: "EditItem",
    component: () => import("../pages/EditItem.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/assets/edit",
    name: "EditItem",
    component: () => import("../pages/EditItem.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/assets/:address/:tokenid",
    name: "itemDetails",
    component: () => import("../pages/ItemDetails.vue"),
    meta: {
      requiresAuth: true,
    },
    props: true, // Allow route parameters to be passed as props
  },
  {
    path: "/explore",
    name: "explore",
    props: true,
    component: () => import("../pages/Explore.vue"),
    // meta: {
    //   requiresAuth: false,
    // },
  },
  {
    path: "/buyticket",
    name: "BuyTicket",
    component: () => import(`../pages/BuyTicket.vue`),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/myticket",
    name: "MyTicket",
    component: () => import(`../pages/MyTicket.vue`),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/:walletAddress",
    name: "author",
    component: () => import("../pages/Author.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import("../pages/AboutUs.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/activity",
    name: "activity",
    component: () => import("../pages/Activity.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/ranking",
    name: "ranking",
    component: () => import("../pages/Ranking.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import("../pages/Blog.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/blog-detail-:id",
    name: "NewsDetail",
    component: () => import("../pages/NewsDetail.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/blog-detail",
    name: "blog-details",
    component: () => import("../pages/BlogDetail.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/connect",
    name: "connect",
    component: () => import("../pages/connect.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/request",
    name: "activity",
    component: () => import("../pages/request.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/purchases-sales",
    name: "purchases-sales",
    component: () => import("../pages/PurchasesSales.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/favorites",
    name: "Favorites",
    component: () => import("../pages/Friendlist.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/my-friends",
    name: "MyFriends",
    component: () => import("../pages/MyFriends.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/Blocklist",
    name: "Blocklist",
    component: () => import("../pages/Blocklist.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/addfriend",
    name: "addfriend",
    component: () => import("../pages/Addfriend.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/addblock",
    name: "addblock",
    component: () => import("../pages/Addblock.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/redeem",
    name: "redeem",
    component: () => import("../pages/Redeem.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/deposit-enfties",
    name: "deposit-enfties",
    component: () => import("../pages/DepositEnfties.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../pages/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../pages/Account.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/payment-methods",
    name: "payment-methods",
    component: () => import("../pages/PaymentMethods.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/seller-settings",
    name: "seller-settings",
    component: () => import("../pages/SellerSettings.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("../pages/Notifications.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/security",
    name: "security",
    component: () => import("../pages/Security.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/avatar",
    name: "avatar",
    component: () => import("../pages/Avatar.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/party",
    name: "party",
    component: () => import("../pages/Party.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/collections/create",
    name: "create",
    component: () => import("../pages/Create.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/collections/create-single",
    name: "CreateSingle",
    component: () => import("../pages/CreateSingle.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/collections/create-multiple",
    name: "create-multiple",
    component: () => import("../pages/CreateMultiple.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/create",
    name: "create",
    component: () => import("../pages/Create.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/collections",
    name: "collections",
    component: () => import(`../pages/Collections.vue`),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/assets/:collection/:title",
    name: "ExploreCollection",
    props: true,
    component: () => import(`../pages/ExploreCollection.vue`),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/offers",
    name: "Offer",
    component: () => import("../pages/Offers.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../pages/Contact.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/privacypolicy",
    name: "privacy policy",
    component: () => import("../pages/privacypolicy.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/pages/NotFound"),
    meta: {
      requiresAuth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        left: 0,
        top: 0,
      };
    }
  },
});

const isTokenExpired = () => {
  const jwt = sessionStorage.getItem("jwt");
  // eslint-disable-next-line no-extra-boolean-cast
  if (!!jwt) {
    const expTimestampInSeconds = jwt_decode(jwt).exp;
    const currentTimestampInSeconds = Math.floor(new Date().getTime() / 1000);

    return expTimestampInSeconds > currentTimestampInSeconds;
  }
  return null;
};

router.beforeEach((to, _, next) => {
  const { meta } = to;
  const tokenExpired = isTokenExpired();

  if (meta.requiresAuth && !tokenExpired) {
    next({
      name: "connect",
      query: { redirect: to.fullPath }, // Store the intended route
    });
  } else next();
});


export default router;
