import { createStore } from "vuex";
import auth from "@/store/modules/auth";
import blockchain from "@/store/modules/blockchain";

export const store = createStore({
  modules: {
    blockchain,
    auth,
  },
});
