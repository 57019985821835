<template>
  <section class="login-section section-space-b pt-4 pt-md-5 mt-md-3">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-xl-6 mx-auto">
          <h1 class="mt-0 mb-2">{{ SectionData.loginDataTwo.sectionTitle }}</h1>
          <p class="mb-4">{{ SectionData.loginDataTwo.sectionParagraph }}</p>

          <ul class="row g-gs nav nav-tabs nav-tabs-s2" id="myTab" role="tablist">
            <li
              class="nav-item col-12 col-sm"
              role="presentation"
              v-for="list in SectionData.loginTabNav"
              :key="list.id"
            >
              <button
                class="nav-link"
                :class="list.isActive"
                :id="list.slug"
                data-bs-toggle="tab"
                :data-bs-target="list.bsTarget"
                type="button"
              >
                <img :src="list.img" alt="" class="icon icon-svg" />
                <span class="nav-link-title mt-2 mt-sm-3">
                  {{ list.title }}
                </span>
              </button>
            </li>
          </ul>
          <div class="gap-2x"></div>
          <!-- end gap -->
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="venly-connect" role="tabpanel" aria-labelledby="venly-connect-tab">
              <div class="card-media card-media-s3 text-center">
                <div class="card-media-body">
                  <h3 class="mb-4">
                    {{ isConnected ? SectionData.loginDataTwo.titleFinal : SectionData.loginDataTwo.titleThree }}
                  </h3>
                  <div class="btn btn-dark" @click="() => connectWithProvider('venly')">
                    {{ isConnected ? "Connected!" : SectionData.loginDataTwo.btnText }}
                  </div>
                </div>
              </div>
              <!-- end card-media -->
            </div>
            <div class="tab-pane fade" id="meta-mask" role="tabpanel" aria-labelledby="meta-mask-tab">
              <div class="card-media card-media-s3 text-center">
                <div class="card-media-body">
                  <h3 class="mb-4">
                    {{ isConnected ? SectionData.loginDataTwo.titleFinal : SectionData.loginDataTwo.title }}
                  </h3>
                  <div class="btn btn-dark" @click="() => connectWithProvider('metamask')">
                    {{ isConnected ? "Connected!" : SectionData.loginDataTwo.btnText }}
                  </div>
                </div>
              </div>
              <!-- end card-media -->
            </div>
            <!-- <div class="tab-pane fade" id="wallet-connect" role="tabpanel" aria-labelledby="wallet-connect-tab">
              <div class="card-media card-media-s3 text-center">
                <div class="card-media-body">
                  <h3 class="mb-4">
                    {{ isConnected ? SectionData.loginDataTwo.titleFinal : SectionData.loginDataTwo.titleTwo }}
                  </h3>
                  <div class="btn btn-dark" @click="() => connectWithProvider('walletConnect')">
                    {{ isConnected ? "Connected!" : SectionData.loginDataTwo.btnText }}
                  </div>
                </div>
              </div>

            </div> -->
            <!-- end tab-pane -->
          </div>
          <!-- end tab-content -->
        </div>
        <!-- end col-lg-7 -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- end login-section -->
</template>

<script>
import SectionData from "@/store/store.js";
// import jwt_decode from "jwt-decode";
// import axios from 'axios';
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      SectionData,
    };
  },
  setup() {
    // Get toast interface
    const toast = useToast();

    // Make it available inside methods
    return { toast };
  },
  async created() {},
  unmounted() {
    // this.$emitter.off("accounts-change");
  },

  async mounted() {
    if (this.$route.query?.auth === "not-authenticated") {
      await this.$store.dispatch("blockchain/disconnect");
      window.location.replace("/connect");
    }
  },
  methods: {
    async connectWithProvider(provider) {
  // Attempt to establish the connection
  await this.$store.dispatch("blockchain/connect", provider);
  await this.$store.dispatch("blockchain/getLatestBlock");

  // Assuming successful connection, set necessary cookies
  this.$cookies.set("user", this.$store.state.blockchain.user);
  this.$cookies.set("sessionToken", this.$store.state.blockchain.sessionToken);

  // Redirect user after successful login
  // Check if the redirect query parameter exists
  const redirectUrl = this.$route.query.redirect || '/'; // Fallback to home if no redirect URL
  this.$router.push(redirectUrl); // Redirect to the intended URL or home
},
  },
  watch: {
    networkError(message) {
      if (message) this.toast.error(message);
    },
    metamaskInitErrorMessage(message) {
      if (message) this.toast.error(message);
    },
  },
  computed: {
    networkError() {
      return this.$store.state.blockchain.networkError;
    },
    isConnected() {
      return this.$store.state.blockchain.isConnected;
    },
    metamaskInitErrorMessage() {
      return this.$store.state.blockchain.metamaskInitErrorMessage;
    },
  },
};
</script>
