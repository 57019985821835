// metamask.js
export const metamaskService = {
  init({ dispatch }) {
    // Configurar el listener de chainChanged si el proveedor es MetaMask
    const handleChainChanged = async (chainId) => {
      console.log("Cambio de red detectado en MetaMask:", chainId);
      // Desconectar al usuario u realizar otras acciones según tus necesidades
      const provider = sessionStorage.getItem("selectedProvider");
      if (provider === "metamask") {
        await dispatch("blockchain/disconnect");
        window.location.replace("/connect");
      }
    };

    window.ethereum?.on("chainChanged", handleChainChanged);

    // Asegúrate de eliminar el listener cuando la aplicación se cierre o el componente principal se destruya
    window.addEventListener("beforeunload", () => {
      window.ethereum?.off("chainChanged", handleChainChanged);
    });
  },
};
